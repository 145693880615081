<template>
    <footer class="main-footer d-flex">
        <div class="ms-auto bv text-center me-2">
          Copyright &copy;  

          Built on <a class="text-primary text-decoration-none" href="www.gearhealthsystem.com">Gear Health System</a>
        </div>
    </footer>
</template>

<style>
    .main-footer {
        padding: 20px;
        display: inline-block;
        width: 100%;
    }
</style>

<script>
export default {
    name: 'Footer',
    props: ['center']
}
</script>