<template>
    <!-- Modal -->
    <div class="modal fade" id="processDelivery" tabindex="-1" role="dialog" aria-labelledby="processDeliveryLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <span class="d-block">
                        <h5 class="content-title-min">Process Delivery</h5>
                        <p class="text-muted">Move order from processing to pending.</p>
                    </span>
                </div>
                            
                <div class="modal-body pt-0">
                    <div class="row">
                        <div class="col-md-12 col-sm-12">
                            <status v-model:status_data=status_data></status>

                            <div class="form-row">
                                <label class="mb-2 fw-bold" for="featured">Status</label>

                                <div class="input-group">
                                    <select v-model="data.status" id="status" class="form-select">
                                        <option></option>
                                        <option value="pending">Pending</option>
                                    </select>
                                </div>

                                <small v-if="errors.status" class="text-danger">
                                    <font-awesome-icon :icon="['fas', 'info-circle']"></font-awesome-icon>
                                    {{ errors.status }}
                                </small>
                            </div>                                              
                        </div>
                    </div>

                    <form action="#" @submit.prevent="handleSubmit()">
                        <button class="btn bg-orange text-uppercase text-white mt-4 w-100">
                            <span class="m-0" style="letter-spacing: 1px">Finish</span> <font-awesome-icon class="ms-3" :icon="['fas', 'check']"></font-awesome-icon>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '../../assets/scss/layout/_form.scss';
    @import '../../assets/scss/layout/_lists.scss';
    @import '../../assets/scss/helpers/_breakpoints.scss';
    
    .side-menu {
        @include media("<=tablet") {
            display: none;
        }

        p {
            cursor: pointer;
        }
    }

    .top-menu {
        display: none;

        @include media("<=tablet") {
            display: flex;
        }

        p {
            cursor: pointer;
        }
    } 

    #cards {
        display: block!important;

        .card :before {
            background-color: transparent;
        }

        .card {
            cursor: pointer;

            .big-icons {
                font-size: 5rem;

                &:hover > & {
                    color: $orange-color!important;
                }
            }             
        } 
    }  
    
    .input-group {
        .input-group-text {
            background: #eee;
        }

        .form-control {
            background: #eee;
        }
    }
    
</style>

<script>
    import Status from '../../components/Status';

    export default {
        name: 'DeliveryModal',
        props: ['delivery_id'],
        components: {
            Status
        },
        data() {
            return {
                data: {
                    status: null
                },
                errors: {
                    status: null
                },
                stock: [],
                screen: 0,
                status_data: {
                    "success": false,
                    "success_message": 'Form Submission was successful!',
                    "error": false,
                    "error_message": 'There are some errors.',
                    "loading": false
                },
                footer_index: null,
                quantity: null,
                stock_ids: [],
                page: 0,
                size: 10,
                last_id: 1
            }
        },
        methods: {
            handleSubmit() {
                this.status_data.loading = true

                this.axios.put('api/delivery/' + this.delivery_id, this.data).then(() => {
                    this.status_data.loading = false
                    this.status_data.error = false
                    this.status_data.success = true
                    this.status_data.success_message = 'Delivery Details Updated!'
                }).catch((error) => {
                    this.status_data.loading = false
                    this.status_data.error = true
                    this.status_data.success = false
                    this.status_data.error_message = error.response.data.data

                    console.log(error);
                })
            },
        }
    }
</script>