<template>
    <transition v-if="!loading" name="fade">
        <div id="wrapper">
            <sidebar :page="'Manage Orders'" :sidebar_data=sidebar_data></sidebar>
            <div class="body">
                <div class="backdrop" v-on:click="close()"></div>
                
                <admin-header :title="'Manage Site'" :tagline="'All your controls, in one place.'" v-model:all_screens=all_screens v-model:screen=screen v-model:url=url :user=user style="margin-bottom: 3rem;"></admin-header>

                <div class="container mb-4">
                    <div class="content-wrapper">
                        <div class="content mx-auto">
                            <section-title v-if="title.status == true" :title=title.title :actions=title.actions></section-title>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="input-group mb-3">
                                        <status v-model:status_data=status_data></status>

                                        <form action="#" @submit.prevent="fetchResults()" style="width: inherit">
                                            <div class="input-group mb-3">
                                                <input type="text" class="form-control search" v-model="search" placeholder="Search through system." aria-label="search" aria-describedby="search-submit">

                                                <span class="input-group-text search-icon" id="search-submit">
                                                    <button class="btn btn-transparent" type="submit">
                                                        <font-awesome-icon class="icon" :icon="['fa', 'search']" /> 
                                                    </button>
                                                </span>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-3">
                                <div class="col-md-6 col-sm-12 col-lg-6" v-for="({cart, price, delivery_to, status, id}, index) in delivery_data" :key="index">
                                    <div class="card mb-3" style="box-shadow: 0 4px 6px #eee; border-color: #fafafa">
                                    <div class="card-body p-3">
                                        <div class="row">
                                        <h6 class='text-dark fw-bold mt-2 mb-1 text-capitalize'>Order {{ id }} </h6>
                                        <small class="text-muted mb-3 fst-italic">Created at 2022-10-12</small>
                                        </div>

                                        <div class="row">
                                        <div class="col">
                                            <p> <font-awesome-icon class="text-dark me-2" :icon="['fa', 'circle-notch']"></font-awesome-icon> Delivery to {{ delivery_to }} </p>
                                            <p> <font-awesome-icon class="text-dark me-2" :icon="['fa', 'money-bill-wave']"></font-awesome-icon> KSH. {{ price }} </p>
                                        </div>
                                        </div>

                                        <div class="row">
                                        <p class="mb-3">
                                            <span class="fw-bold bg-dark text-white p-1 rounded-2">
                                                Purchases
                                            </span>
                                        </p>
                                        </div>

                                        <div class="row d-block">
                                        <div class="col" v-for="(stock, index) in cart" :key="index">
                                            <p> <font-awesome-icon class="text-dark me-2" :icon="['fa', 'prescription']"></font-awesome-icon> {{ stock.name }} </p>
                                        </div>
                                        </div>                          

                                        <div class="d-flex">
                                            <div class="d-block mt-auto">
                                                <p class="mb-0 mt-0 fw-bold text-success" v-if="status">
                                                <font-awesome-icon class="me-2" :icon="['fas', 'check-circle']" style="margin-top: 3px"></font-awesome-icon> 
                                                <span class="text-uppercase">{{ status }}</span>
                                                </p>
                                            </div>
                                            <div class="d-block ms-auto">
                                                <p class="mb-0 mt-0 fw-bold text-dark" style="cursor: pointer;" data-bs-toggle="modal" data-bs-target="#processDelivery" @click="loadModal(id)">
                                                    Process Delivery  
                                                    <font-awesome-icon class="ms-2" :icon="['fas', 'angle-right']" style="margin-top: 3px"></font-awesome-icon> 
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>  

                                <div class="col-md-12 col-lg-12 d-flex mt-4">
                                    <div class="mx-auto">
                                        <button class="btn bg-orange text-white me-2" @click="loadPrevious()" v-if="page > 0"> 
                                            <font-awesome-icon :icon="['fas', 'angle-left']"></font-awesome-icon>
                                        </button>
                                        
                                        <button class="btn bg-orange text-white text-uppercase" v-if="delivery_data.length > 0" @click="loadMore()"> 
                                            <p class="m-0 ps-2 pe-2" style="letter-spacing: 1px">Load more</p>
                                        </button>
                                    </div>
                                </div>  
                            </div>
                        </div>                                          
                    </div>
                </div>
                
                <main-footer></main-footer>
            </div>

            <delivery-modal :delivery_id="delivery_id"></delivery-modal>
        </div>
    </transition>
</template>

<style lang="scss" scoped>
    @import '../../assets/scss/layout/_lists.scss';

    #cards {
        display: block!important;
    }
</style>

<script>
import MainFooter from '../../components/Footer'
import Sidebar from '../../components/AutoSidebar'
import AdminHeader from '../../components/Header'
import SectionTitle from '../../components/SectionTitle'
import Status from '../../components/Status'
import DeliveryModal from '../../components/Modals/DeliveryModal'
import SidebarData from '../../assets/json/Admin/SidebarData'

export default {
    name: 'AdminDelivery',
    components: {
        AdminHeader,
        MainFooter,
        Sidebar,
        SectionTitle,
        Status,
        DeliveryModal
    },
    data() {
        return {
            delivery_data: [],
            size: 25,
            page: 0,
            last_id: 0,
            status: 'pending',
            delivery_id: null,
            title: {status: true, title: 'All Pending Deliveries', actions: [
                {name: 'Create Order', type: 'modal', action_url: 'order'}
            ]},            
            sidebar_data: SidebarData,  
            all_screens: [
                {title: 'All Orders', screen: 1, url: 'api/orders'}
            ], 
            status_data: {
                "success": false,
                "success_message": 'Form Submission was successful!',
                "error": false,
                "error_message": 'There are some errors.',
                "loading": false
            },            
            screen: 1,
            search: null
        }
    },
    watch: {
        user: function() {
            this.ifNotAdmin()
        }
    },    
    computed: {
        user () {
            return this.$store.state.user
        },
        loading () {
            return this.$store.state.loading
        }       
    },     
    mounted() {
        this.$store.dispatch("getUser")
        this.getDatabyPage()
    },
    methods: {  
        ifNotAdmin() {
            if( !this.user.admin) {
                this.$router.push({name: 'Home'});
            }
        },               
        getDatabyPage() {
            let url = '/api/delivery' 

            this.axios.get(url + '?size=' + this.size + '&page=' + this.page + '&last_id=' + this.last_id + '&status=processing').then((response) => {
                this.delivery_data = response.data.data
                this.last_id = response.data.last_id
            }).catch((errors) => {
                console.log(errors);
            })  
        },
        loadMore() {
            this.page = this.page + 1
            this.getDatabyPage()          
        },
        loadPrevious() {
            if(this.page > 0) {
                this.page = this.page - 1
            }

            this.getDatabyPage()
        },
        updateOrder(value, id) {
            this.status_data.loading = true
            let status = {
                status: value
            }

            this.axios.put('/api/orders/' + id, status).then(() => {
                this.status_data.loading = false
                this.status_data.error = false
                this.status_data.success = true
                this.status_data.success_message = 'Order updated!'

                this.getDatabyPage()
            }).catch((error) => {
                this.status_data.loading = false
                this.status_data.error = true
                this.status_data.success = false
                this.status_data.error_message = 'Order not updated.' + error.response.data.message
            })
        },
        loadModal(id) {
            this.delivery_id = id
        }
    }

}
</script>
